import { EnvironmentManager } from "calling/EnvironmentManager";
import { Environment } from "types/environment";

export default function setApiDetailsForEnvironment(environment: string) {
  var selectedEnvironment: Environment;
  switch (environment) {
    case "development":
      selectedEnvironment = Environment.Development;
      break;
    case "staging":
      selectedEnvironment = Environment.Staging;
      break;
    case "production":
      selectedEnvironment = Environment.Production;
      break;
    case "custom":
      selectedEnvironment = Environment.Custom;
      break;
    default:
      throw new Error("Invalid environment");
  }
  EnvironmentManager.getInstance().setApiDetailsForEnvironment(
    selectedEnvironment
  );
}
