import { updateRoomApi } from "./updateRoom";
import LOG from "logging/Logger";
import { ParticipationManager } from "calling/ParticipationManager";
import ContentMetadataApiClient from "api/contentMetadata/ContentMetadataApiClient";

export interface UpdateContentProps {
  contentUuid: string;
}
export async function updateContentApi(updateContentProps: UpdateContentProps) {
  LOG.info("Update content: " + updateContentProps.contentUuid);

  const room = ParticipationManager.getInstance().getRoom();
  if (!room) {
    throw new Error("Room is undefined");
  }

  var providerId = "";
  const metadata = (await ContentMetadataApiClient.getInstance().getMetadata(
    updateContentProps.contentUuid
  )) as any;

  LOG.info("Metadata:" + JSON.stringify(metadata));
  
  const svod = metadata.waystowatch?.svod;
  LOG.info("Svod: " + JSON.stringify(svod));

  if (svod && svod.length > 0) {
    providerId = svod[0].providerid;
  } else {
    throw new Error(
      "No provider id. This means that this content can't be played."
    );
  }

  await updateRoomApi({
    entityId: updateContentProps.contentUuid,
    providerId: providerId,
    playbackOffset: new Date().getTime(),
    appContentData: "VOD",
  });
}
