import RoomManagementApiClient from "api/roomManagement/RoomManagementApiClient";
import { EnvironmentManager } from "calling/EnvironmentManager";
import LOG from "logging/Logger";
export interface SubscribeToParticipantUpdatesProps {
  roomCode: string;
  callback: (event: string) => void;
}

export async function subscribeToParticipantUpdatesApi(
  subscribeToParticipantUpdatesProps: SubscribeToParticipantUpdatesProps
) {
  const environmentManager = EnvironmentManager.getInstance();
  const apiDetails = environmentManager.getApiDetails();
  if (!environmentManager.isApiDetailsValid()) {
    throw new Error("Api details not correct");
  }
  LOG.info(
    `Subscribe to participant updates for room: ${subscribeToParticipantUpdatesProps.roomCode}`
  );
  const apiClient = RoomManagementApiClient.getInstance(apiDetails!);
  const input = { roomCode: subscribeToParticipantUpdatesProps.roomCode };
  const result = apiClient.onParticipantUpdate(
    input,
    subscribeToParticipantUpdatesProps.callback
  );
  return result;
}
