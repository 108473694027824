import RoomManagementApiClient from "api/roomManagement/RoomManagementApiClient";
import { EnvironmentManager } from "calling/EnvironmentManager";
import { ParticipationManager } from "calling/ParticipationManager";
import LOG from "logging/Logger";

//Predefined content
export const defaultEntityId = "8530720033867659112";
export const defaultProviderId = "8871189334638732105";

export async function createRoomApi() {
  const environmentManager = EnvironmentManager.getInstance();
  const apiDetails = environmentManager.getApiDetails();
  if (!environmentManager.isApiDetailsValid()) {
    throw new Error("Api details not correct");
  }

  const apiClient = RoomManagementApiClient.getInstance(apiDetails!);
  const alphaEnabled = false;
  const liveContent = false;
  const entityId = defaultEntityId;
  const appContentData = null;
  const providerId = defaultProviderId;

  const metadata = {
    entityId,
    lightningPlayback: true,
    playbackMode: "VIDEO_MODE_OVERLAY",
    layout: "SIDEBAR",
    contentPosition: {
      left: 0,
      top: 0,
      width: 1920,
      height: 1080,
    },
  };

  let input = {
    entityId,
    appContentData,
    providerId,
    alphaEnabled,
    liveContent,
    metadata: JSON.stringify(metadata),
    isPlaying: true,
    playbackOffset: new Date().getTime(),
  };

  const result = await apiClient.createRoom(input);
  ParticipationManager.getInstance().setRoom(result);
  LOG.info("Created new room.");

  return result;
}
