import { ApiDetails } from "types/apiDetails";
import { Environment } from "types/environment";
const {
  REACT_APP_DEV_ROOM_MANAGEMENT_API_GRAPHQL_URL,
  REACT_APP_DEV_ROOM_MANAGEMENT_API_REALTIME_GRAPHQL_URL,
  REACT_APP_DEV_ROOM_MANAGEMENT_API_KEY,
  REACT_APP_STAGING_ROOM_MANAGEMENT_API_GRAPHQL_URL,
  REACT_APP_STAGING_ROOM_MANAGEMENT_API_REALTIME_GRAPHQL_URL,
  REACT_APP_PROD_ROOM_MANAGEMENT_API_GRAPHQL_URL,
  REACT_APP_PROD_ROOM_MANAGEMENT_API_REALTIME_GRAPHQL_URL,
} = process.env;

export class EnvironmentManager {
  private static instance: EnvironmentManager;
  private apiDetails?: ApiDetails;
  private environment: Environment;

  private constructor() {
    this.apiDetails = EnvironmentApiDetails.development;
    this.environment = Environment.Development;
  }

  public static getInstance(): EnvironmentManager {
    if (!EnvironmentManager.instance) {
      EnvironmentManager.instance = new EnvironmentManager();
    }
    return EnvironmentManager.instance;
  }

  setApiDetails({
    graphQLEndpoint,
    realtimeGraphQLEndpoint,
    apiKey,
  }: ApiDetails) {
    let newGraphQLEndpoint = this.apiDetails?.graphQLEndpoint;
    let newRealtimeGraphQLEndpoint = this.apiDetails?.realtimeGraphQLEndpoint;
    let newApiKey = this.apiDetails?.apiKey;

    if (graphQLEndpoint !== undefined && graphQLEndpoint.length >= 0) {
      newGraphQLEndpoint = graphQLEndpoint;
    }
    if (
      realtimeGraphQLEndpoint !== undefined &&
      realtimeGraphQLEndpoint.length >= 0
    ) {
      newRealtimeGraphQLEndpoint = realtimeGraphQLEndpoint;
    }

    if (apiKey !== undefined && apiKey.length >= 0) {
      newApiKey = apiKey;
    }

    this.apiDetails = {
      graphQLEndpoint: newGraphQLEndpoint,
      realtimeGraphQLEndpoint: newRealtimeGraphQLEndpoint,
      apiKey: newApiKey,
    };
  }

  getApiDetails() {
    return this.apiDetails;
  }

  getEnvironment() {
    return this.environment;
  }

  isApiDetailsValid() {
    return (
      this.apiDetails &&
      this.apiDetails.apiKey &&
      this.apiDetails.apiKey.length > 0 &&
      this.apiDetails.graphQLEndpoint &&
      this.apiDetails.graphQLEndpoint.length > 0 &&
      this.apiDetails.realtimeGraphQLEndpoint &&
      this.apiDetails.realtimeGraphQLEndpoint.length > 0
    );
  }

  setApiDetailsForEnvironment(environment: Environment) {
    this.environment = environment;
    switch (environment) {
      case Environment.Development:
        this.setApiDetails(EnvironmentApiDetails.development);
        break;
      case Environment.Staging:
        this.setApiDetails(EnvironmentApiDetails.staging);
        break;
      case Environment.Production:
        this.setApiDetails(EnvironmentApiDetails.production);
        break;
      case Environment.Custom:
        this.setApiDetails(EnvironmentApiDetails.custom);
        break;
    }
  }
}

export const EnvironmentApiDetails = {
  development: {
    graphQLEndpoint: REACT_APP_DEV_ROOM_MANAGEMENT_API_GRAPHQL_URL,
    realtimeGraphQLEndpoint:
      REACT_APP_DEV_ROOM_MANAGEMENT_API_REALTIME_GRAPHQL_URL,
    apiKey: REACT_APP_DEV_ROOM_MANAGEMENT_API_KEY,
  },
  staging: {
    graphQLEndpoint: REACT_APP_STAGING_ROOM_MANAGEMENT_API_GRAPHQL_URL,
    realtimeGraphQLEndpoint:
      REACT_APP_STAGING_ROOM_MANAGEMENT_API_REALTIME_GRAPHQL_URL,
    apiKey: "",
  },
  production: {
    graphQLEndpoint: REACT_APP_PROD_ROOM_MANAGEMENT_API_GRAPHQL_URL,
    realtimeGraphQLEndpoint:
      REACT_APP_PROD_ROOM_MANAGEMENT_API_REALTIME_GRAPHQL_URL,
    apiKey: "",
  },
  custom: {
    graphQLEndpoint: "",
    realtimeGraphQLEndpoint: "",
    apiKey: "",
  },
};
