import RoomManagementApiClient from "api/roomManagement/RoomManagementApiClient";
import { EnvironmentManager } from "calling/EnvironmentManager";
import { ParticipationManager } from "calling/ParticipationManager";
import { ACSCallManager } from "calling/ACSCallManager";

export interface SetMicrophoneStateProps {
  state: boolean;
}

export async function setMicrophoneStateApi(
  setMicrophoneStateProps: SetMicrophoneStateProps
) {
  const environmentManager = EnvironmentManager.getInstance();
  const apiDetails = environmentManager.getApiDetails();
  if (!environmentManager.isApiDetailsValid()) {
    throw new Error("Api details not correct");
  }
  const participationDetails =
    ParticipationManager.getInstance().getParticipationDetails();
  if (!participationDetails) {
    throw new Error("Participation details is empty");
  }
  await ACSCallManager.getInstance().setMicrophoneState(
    setMicrophoneStateProps.state
  );
  const input = { micEnabled: setMicrophoneStateProps.state };
  await RoomManagementApiClient.getInstance(apiDetails!).updateParticipant(
    input,
    participationDetails.participant.id
  );
  return setMicrophoneStateProps.state;
}
