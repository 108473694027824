import RoomManagementApiClient from "api/roomManagement/RoomManagementApiClient";
import { EnvironmentManager } from "calling/EnvironmentManager";
import { ParticipationManager } from "calling/ParticipationManager";
import LOG from "logging/Logger";

export interface GetRoomProps {
  roomCode: string;
}

export async function getRoomApi(getRoomProps: GetRoomProps) {
  const environmentManager = EnvironmentManager.getInstance();
  const apiDetails = environmentManager.getApiDetails();
  if (!environmentManager.isApiDetailsValid()) {
    throw new Error("Api details not correct");
  }
  LOG.info(`Get room: ${getRoomProps.roomCode}`);
  const apiClient = RoomManagementApiClient.getInstance(apiDetails!);

  const input = { roomCode: getRoomProps.roomCode };
  const result = await apiClient.getRoom(input);
  ParticipationManager.getInstance().setRoom(result);
  return result;
}
